import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import Container from "../Container"
import PreserveScrollLink from "../links/PreserveScrollLink"
import { isEmpty } from "lodash"
import CollectionProducts from "./CollectionProducts"
import PageComponents from "../PageComponents"
import SubNav from "../nav/SubNav"

const FilterContainer = styled.div`
  column-gap: 8px;
  display: grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  justify-content: flex-start;

  a {
    border-radius: 25px;
    padding: 6px 16px;
  }

  .active-link {
    background: var(--color-gray);
  }
`

/**
 * Component for collection page. This is rendered in two different ways:
 *
 *   - `collection === parent` - both point to the parent collection
 *   - `collection` is the sub collection and `parent` is the parent collection
 */
const CollectionPage = ({ collection, filter, parent }) => {
  const collectionPath = col =>
    col.filterPaths.gender[filter.gender] || col.path.current

  return (
    <>
      {collection.components && (
        <PageComponents components={collection.components} />
      )}
      <Container>
        <SubNav
          heading="Collections"
          items={[
            { title: parent.title, to: collectionPath(parent) },
            ...(parent.subCollections || []).map(sub => ({
              title: sub.title,
              to: collectionPath(sub),
            })),
          ]}
        />

        {!isEmpty(collection.filterPaths.gender) &&
          collection.showGenderSelector && (
            <FilterContainer>
              <PreserveScrollLink
                activeClassName="active-link"
                to={collection.path.current}
              >
                All
              </PreserveScrollLink>

              {collection.filterPaths.gender.mens && (
                <PreserveScrollLink
                  activeClassName="active-link"
                  to={collection.filterPaths.gender.mens}
                >
                  Mens
                </PreserveScrollLink>
              )}

              {collection.filterPaths.gender.womens && (
                <PreserveScrollLink
                  activeClassName="active-link"
                  to={collection.filterPaths.gender.womens}
                >
                  Womens
                </PreserveScrollLink>
              )}
            </FilterContainer>
          )}
      </Container>

      <Container>
        <CollectionProducts
          maxColumns={collection.maxColumns}
          products={collection.shopifyCollection.products}
        />
      </Container>

      {collection.bottomComponents && (
        <PageComponents components={collection.bottomComponents} />
      )}
    </>
  )
}

CollectionPage.propTypes = {
  collection: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  parent: PropTypes.object.isRequired,
}

export default CollectionPage
