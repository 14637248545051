import PropTypes from "prop-types"
import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "@emotion/styled"
import { ResponsiveGrid } from "../../vendor/hoa/responsive_grid"
import CollectionProduct from "../products/CollectionProduct"
import { isNumber } from "lodash"
import { observer } from "mobx-react-lite"
import StoreContext from "../context/StoreContext"

const Styled = styled.div`
  display: grid;
  column-gap: var(--grid-gap);
  grid-template-columns: repeat(var(--grid-columns), 1fr);

  @media (max-width: ${props => props.theme.phoneMax}) {
    margin: 15px 0 0;
    row-gap: 35px;
  }

  @media (min-width: ${props => props.theme.tabletMin}) {
    margin: 20px 0 0;
    row-gap: 45px;
  }
`

const Spacer = styled.div`
  height: 50000px;
`

const firstPageLimit = 24
const nextPageLimit = 48

const CollectionProducts = ({ maxColumns = 4, products }) => {
  const { productStore } = useContext(StoreContext)

  const [paginationMax, setPaginationMax] = useState(firstPageLimit)
  const [isLoaded, setLoaded] = useState(false)

  const containerRef = useRef(null)

  useEffect(() => {
    productStore.loadProductAvailability(
      products.slice(0, paginationMax).map(({ id }) => id)
    )

    if (isNumber(window.history.state?.productLimit)) {
      const limit = window.history.state.productLimit

      setPaginationMax(limit)
    }

    setLoaded(true)

    const handleScroll = () => {
      const elem = containerRef.current
      const threshold = window.innerHeight

      if (!elem) {
        // this happens on Safari when using the back button while scrolling:
        // https://app.asana.com/0/1200299136882357/1201043253173289
        console.warn("scroll container is null; known issue on Safari")
        return
      }

      const distance = elem.getBoundingClientRect().bottom - window.innerHeight

      if (distance < threshold && paginationMax < products.length) {
        setPaginationMax(oldVal => {
          const productLimit = oldVal + nextPageLimit

          window.history.replaceState(
            { productLimit },
            "",
            window.location.pathname
          )

          return productLimit
        })
      }
    }

    document.addEventListener("scroll", handleScroll)

    return () => {
      document.removeEventListener("scroll", handleScroll)
    }
  }, [paginationMax, products.length])

  return (
    <>
      <ResponsiveGrid
        columns={Math.min(2, maxColumns)}
        tabletColumns={Math.min(3, maxColumns)}
        desktopColumns={maxColumns}
      >
        <Styled ref={containerRef}>
          {products.slice(0, paginationMax).map(product => (
            <CollectionProduct key={product._id} product={product} />
          ))}
        </Styled>
      </ResponsiveGrid>

      {/* Take up space to avoid having scroll position truncated before initial pagination */}
      {!isLoaded && <Spacer />}
    </>
  )
}

CollectionProducts.propTypes = {
  maxColumns: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default observer(CollectionProducts)
